<template>
  <div class="wrapper">
    <div class="animated fadeIn">
      <BRow>
        <BCol cols="12" md="12">
          <BCard footer-tag="footer" header-tag="header">
            <div slot="header">
              <BIconPeopleFill/>
              <strong> 통합회원관리 </strong>
              <div class="card-header-actions">
                <small class="text-muted">회원 정보를 관리합니다.</small>
              </div>
            </div>

            <BRow class="mb-2">
              <BCol cols="4">
                <BInputGroup size="sm">
                  <BFormInput size="sm" v-model="newUserId" placeholder="user id"></BFormInput>
                  <BFormInput size="sm" v-model="newUserPwd" placeholder="password"></BFormInput>
                  <BButton variant="warning" size="sm" @click="createUser">
                    <BIconPlusCircleFill/> 추가
                  </BButton>
                </BInputGroup>
              </BCol>
              <BCol cols="4">
                <BInputGroup size="sm">
                  <template v-slot:prepend>
                    <BFormSelect v-model="searchField"
                                 :options="searchOpts"
                                 size="sm"/>
                  </template>
                  <BFormInput v-model="searchWord"
                              size="sm"
                              type="text"
                              v-on:keyup.enter="getMemberList"/>
                </BInputGroup>
              </BCol>

              <BCol class="text-right">
                <BInputGroup size="sm">
                  <BInputGroupAppend>
                    <BFormSelect v-model="perPage" :options="[10,20,50,100,200]" size="sm"/>
                  </BInputGroupAppend>
                  <BInputGroupAppend>
                    <BButtonGroup>
                      <BButton size="sm" variant="primary" @click="getMemberList">
                        <BIconArrowRepeat/>
                      </BButton>
                      <BButton class="ml-1" size="sm" variant="info" @click="exportExcel">
                        <BIconFileSpreadsheetFill/>
                        Download
                      </BButton>
                    </BButtonGroup>
                  </BInputGroupAppend>
                </BInputGroup>
              </BCol>
            </BRow>

            <vue-excel-editor
                ref="memberGrid"
                v-model="flattenedData"
                :localized-label="$localizedLabel"
                :page="perPage"
                :readonly-style="{backgroundColor:'#EFE'}"
                class="mb-1"
                filter-row
                width="100%"
                @select="rowSelected"
                @update="updateXcostUser">
              <vue-excel-column field="_id" key-field label="SEQ" invisible/>
              <vue-excel-column field="userNo" label="번호" readonly text-align="right" width="70px"/>
              <vue-excel-column field="userId" label="ID" readonly width="90px"/>
              <!--                            <vue-excel-column field="pwdHash"   label="" type="" width=""/>-->
              <vue-excel-column field="name" label="성명" width="80px"/>
              <vue-excel-column field="email" label="email" width="80px"/>
              <vue-excel-column :options="roleMap" field="role" label="역할" type="map" width="100px"/>
<!--              <vue-excel-column :options="levelMap" field="level" label="등급" type="map" width="100px"/>-->
<!--              <vue-excel-column :to-text="toLocalTime" :to-value="toIsoTime" field="lvEndDt" label="만료일" type="datetime" width="140px"/>-->
              <vue-excel-column field="hpNo" label="휴대폰" width="120px"/>
              <vue-excel-column field="telNo" label="전화" width="80px"/>
              <vue-excel-column field="nick" label="별명" width="80px"/>
              <vue-excel-column field="birth" label="생일" width="80px"/>
              <vue-excel-column field="zipNo" label="ZIP" width="60px"/>
              <vue-excel-column field="addr" label="주소" width="120px"/>
              <vue-excel-column field="addrDtl" label="상세주소" width="100px"/>
              <vue-excel-column field="compName" label="회사명" width="80px"/>
              <vue-excel-column field="compNo" label="사업자번호" width="100px"/>
              <vue-excel-column field="prName" label="대표자명" width="80px"/>
              <vue-excel-column field="position" label="직책" width="80px"/>
              <!--                            <vue-excel-column field="payments"  label="결제기록" width="80px"/>-->
              <vue-excel-column field="isMailing" label="메일" width="60px"/>
              <vue-excel-column field="isSms" label="문자" width="60px"/>
              <!--                            <vue-excel-column field="agreeVer"  label="동의ver" width="100px"/>-->
              <vue-excel-column field="isLogin" label="로그인" width="80px"/>
              <vue-excel-column :to-text="toLocalTime" :to-value="toIsoTime" field="loginDt" label="로그인일시"
                                width="140px"/>
              <vue-excel-column field="loginIp" label="로그인IP" width="110px"/>
              <vue-excel-column :options="statusMap" field="status" label="상태" type="map" width="80px"/>
              <vue-excel-column field="regDts" label="가입일" type="date" width="110px"/>
              <vue-excel-column :to-text="toLocalTime" field="createdAt" label="등록일" width="130px"/>

            </vue-excel-editor>

            <BRow v-if="false">
              <BCol>

              </BCol>

              <BCol>
                <BProgress :max="totalCount" :value="progCount" height="2rem" show-value variant="warning"/>
              </BCol>
            </BRow>

            <BCollapse id="newAccount" v-model="isRowSelect">
              <BCard class="mt-2" footer-tag="footer" header-tag="header">
                <div slot="header">
                  <BIconPersonFill/>
                  <strong> 회원정보 관리 </strong>
                </div>
                <BRow>
                  <BCol md="6">
                    <BCard>
                      <BForm @submit="clickSaveMember">
                        <BFormGroup class="mb-1" id="form-01" label="아이디" label-cols="2" label-for="input-id" label-align="right">
                          <BFormInput id="input-id" v-model="member.userId" type="text" readonly/>
                        </BFormGroup>
                        <BFormGroup class="mb-1" id="form-02" label="사용자 명" label-cols="2" label-for="input-name" label-align="right">
                          <BFormInput id="input-name" v-model="member.name" type="text"/>
                        </BFormGroup>
                        <BFormGroup class="mb-1" id="form-03" label="별명" label-cols="2" label-for="input-nick" label-align="right">
                          <BFormInput id="input-nick" v-model="member.nick" type="text"/>
                        </BFormGroup>
                        <BFormGroup class="mb-1" id="form-03" label="권한" label-cols="2" label-for="input-role" label-align="right">
                          <BFormSelect id="input-role" v-model="member.role" :options="roleMap"/>
                        </BFormGroup>
                        <div class="text-right">
                          <BButton variant="primary" type="submit"><BIconPen/> 사용자 정보수정</BButton>
                        </div>
                      </BForm>

                      <hr>

                      <BInputGroup>
                        <BFormInput v-model="member.email"></BFormInput>
                        <BInputGroupAppend>
                          <BButton variant="primary" @click="sendPwdResetMail">
                            <img class="mr-1 no-right-click icon-row-btn-15 fc-w" src="@/assets/svg/paper-plane.svg" alt="발송">
                            비밀번호 변경 발송
                          </BButton>
                        </BInputGroupAppend>
                      </BInputGroup>

                      <div class="text-right mt-3 mb-3">
                        <BButton class="mr-2 fc-w" variant="warning" @click="resetPwdForce">
                          <img class="mr-1 no-right-click icon-row-btn-15 fc-w" src="@/assets/svg/paper-plane.svg" alt="발송">
                          비밀번호 초기화 발송
                        </BButton>

                        <BButton variant="danger" @click="deleteMember">
                          <BIconTrashFill/>
                          회원 정보 삭제
                        </BButton>
                      </div>
                    </BCard>


                    <BCard class="mt-2">
                      <div slot="header">
                        <BIconPersonCheckFill/>
                        <strong> 로그인 이력 </strong>
                        <div class="float-right">
                          <BInputGroup size="sm" prepend="조회 시작일">
                            <BFormInput class="small" size="sm" type="date" v-model="fromDts" @input="getUserLogs"/>
                            <BInputGroupAppend>
                              <BButton size="sm" @click="exportUserLogs" :disabled="userLogs.length===0"><BIconDownload/> Excel</BButton>
                            </BInputGroupAppend>
                          </BInputGroup>
                        </div>
                      </div>

                      <vue-excel-editor
                          ref="userLogGrid"
                          v-model="userLogs"
                          :localized-label="$localizedLabel"
                          :page="10"
                          :readonly-style="{backgroundColor:'#EFE'}"
                          class="mb-1"
                          filter-row>

                        <vue-excel-column field="_id" key-field  invisible/>
                        <vue-excel-column field="userId" label="아이디" width="100px"/>
                        <vue-excel-column field="status" label="구분" type="map" :options="{I:'로그인',O:'로그아웃'}" width="70px"/>
                        <vue-excel-column field="accessIp" label="IP주소" width="110px"/>
                        <vue-excel-column field="loginDt" label="로그인" width="125px" :to-text="toLocalTime"/>
                        <vue-excel-column field="logoutDt" label="로그아웃" width="125px" :to-text="toLocalTime"/>
                        <vue-excel-column field="levels" label="라이센스" width="120px"/>
                        <vue-excel-column field="result" label="결과" width="100px"/>
                        <vue-excel-column field="message" label="메세지" width="100px"/>
                        <vue-excel-column field="device" label="디바이스" width="90px"/>
                      </vue-excel-editor>
                    </BCard>
                    <FormulateForm v-if="false" v-model="member" :schema="memberSchema"/>

                  </BCol>

                  <BCol md="6">

                    <div>
                      <BButton class="text-right" variant="info" @click="getUserLicenses">
                        [<b>{{member.userId}}</b>] 라이센스 정보 조회 <BIconArrowClockwise/>
                      </BButton>
                    </div>

                    <BTable small sticky-header responsive hober striped selectable
                            select-mode="single"
                            class="small text-nowrap mt-2"
                            @row-selected="licenseSelected"
                            :items="licenses"
                            :fields="licenseFields" >
                    </BTable>

                    <BCard v-if="license">
                      <div slot="header">
                        <BIconArchiveFill/>
                        <strong> 라이센스 정보 수정 [{{license.level}}] </strong>
                        <div class="card-header-actions">
                          {{license.prdName}}
                        </div>
                      </div>
                      <BRow class="mb-1">
                        <BCol>
                          <BInputGroup prepend="라이센스 종류">
                            <BFormSelect v-model="license.prdCode"
                                         :options="prdOpts"
                            />
                            <BButton variant="primary" @click="setUserLicense('prdCode')">수 정</BButton>
                          </BInputGroup>
                        </BCol>
                      </BRow>
                      <BRow>
                        <BCol cols="9">
                          <BInputGroup size="sm" prepend="라이센스">
                            <BFormInput size="sm" type="date" v-model="license.lvEnd"/>
                            <BInputGroupAppend size="sm">
                              <BInputGroupText>추가일</BInputGroupText>
                            </BInputGroupAppend>
                            <BFormInput class="small" size="sm" type="number" v-model="addLvDay" @input="addDays('lv')"/>
                          </BInputGroup>

                          <BInputGroup size="sm" prepend="유지보수" class="mt-1">
                            <BFormInput size="sm" type="date" v-model="license.maEnd"/>
                            <BInputGroupAppend size="sm">
                              <BInputGroupText>추가일</BInputGroupText>
                            </BInputGroupAppend>
                            <BFormInput class="small" size="sm" type="number" v-model="addMaDay" @input="addDays('ma')"/>
                          </BInputGroup>
                        </BCol>

                        <BCol>
                          <BButton size="sm"
                                   variant="warning"
                                   @click="setUserLicense( 'update')">
                            <BIconArrowUpCircleFill/> 수 정
                          </BButton>
                          <br/>
                          <BButton size="sm" class="mt-1"
                                   variant="danger"
                                   @click="setUserLicense( 'delete')">
                            <BIconTrash2Fill/> 삭 제
                          </BButton>
                        </BCol>
                      </BRow>
                      <BRow class="mt-2">
                        <BCol>
                          <BInputGroup size="sm" prepend="날짜계산">
                            <BFormInput class="small" type="date" size="sm" v-model="dt1" @input="diffDay"/>
                            <BInputGroupAppend size="sm">
                              <BInputGroupText>-</BInputGroupText>
                            </BInputGroupAppend>
                            <BFormInput class="small" type="date" size="sm" v-model="dt2" @input="diffDay"/>
                            <BInputGroupAppend size="sm">
                              <BInputGroupText>=</BInputGroupText>
                            </BInputGroupAppend>
                            <BFormInput class="small" size="sm" v-model="diffDt" readonly/>
                          </BInputGroup>
                        </BCol>
                      </BRow>

                    </BCard>


                    <BButtonGroup>
                      <BButton variant="primary" @click="createLicense" class="mr-2">
                        <BIconBagPlus/>
                        라이센스 추가
                      </BButton>

                      <BButton variant="danger" @click="reCreateLicense">
                        <BIconArchiveFill/>
                        라이센스 초기화
                      </BButton>
                    </BButtonGroup>

                  </BCol>

                  <BCol md="12">
                    <BCard class="mt-2">
                      <div slot="header" style="margin:auto;">
                        <div class="float-left">
                          <BIconGiftFill/>
                          <strong> 전체 결제정보 이력 </strong>
                        </div>
                        <div class="float-right">
                          <label style="color: rgb(255, 60, 60); font-size: 9pt; height: 5px;"> 결제가 완료된 이력만 조회됩니다 </label>
                        </div>
                      </div>
  
                      <vue-excel-editor
                        ref="orderGrid"
                        v-model="rows"
                        :localized-label="$localizedLabel"
                        :page="perPage"
                        :readonly-style="{backgroundColor:'#EFE'}"
                        class="mb-1"
                        filter-row
                        width="100%"
                      >
                        <vue-excel-column field="_id" label="주문번호" readonly type="string" width="80px" :to-text="v=>{return v?v.slice(-6).toUpperCase():'N/A'}"/>
                        <vue-excel-column field="userId" label="주문자ID" width="110px"/>
                        <!-- <vue-excel-column field="loginId" label="이용자ID" width="110px"/> -->
                        <vue-excel-column field="createdAt" label="등록일" type="datetime" width="140px" :to-text="toYmdHm" readonly/>
                        <vue-excel-column field="name" label="성명" width="80px"/>
                        <vue-excel-column field="salesMaster" label="상품명" width="200px" :to-text="v=>{ return v? v.title:''}"/>
                        <vue-excel-column field="products" label="주문제품" width="200px" :to-text="v=>{ return v.length?v.map(i=>{return i.prd_name}):''}" readonly/>
                        <vue-excel-column field="amount" label="금액" readonly type="number" width="80px" :to-text="toComma"/>
                        <vue-excel-column field="payAmount" label="결제액" type="number" width="80px" :to-text="toComma" :to-value="toNumber"/>
                        <vue-excel-column :options="payStatOpts" field="payStat" label="지불상태" readonly type="map" width="80px"/>
                        <vue-excel-column :options="payTypeOpts" field="payType" label="결제" type="map" width="140px"/>
                        <vue-excel-column field="depositor" label="입금자"  width="100px"/>
                        <vue-excel-column field="phoneNo" label="연락처" width="130px"/>
                        <vue-excel-column field="email" label="Email" width="170px"/>
                        <vue-excel-column field="compName" label="회사명" width="100px"/>
                        <!-- <vue-excel-column field="prName" label="대표자명" width="90px"/> -->
                        <!-- <vue-excel-column field="address" label="사업장주소" width="130px"/> -->
                        <!-- <vue-excel-column field="bizKind" label="업태/업종" width="110px"/> -->
                        <!-- <vue-excel-column field="compNo" label="사업자번호" width="110px"/> -->
                        <vue-excel-column field="pgTran" label="TR_ID" readonly type="number" width="60px" />
                        <vue-excel-column field="payDt" label="결제일" type="date" width="88px" readonly :to-text="toYmdHm"/>
                        <vue-excel-column field="approveYn" label="승인" type="checkYN" width="50px"/>
                        <vue-excel-column field="approveDt" label="승인일시" readonly type="datetime" width="140px"/>
                        <vue-excel-column field="cancelYn" label="취소" readonly type="checkYN" width="40px"/>
                        <vue-excel-column field="cancelDt" label="취소일" readonly type="date" width="90px"/>
                        <vue-excel-column field="comment" label="비고" width="140px"/>
                        <!-- <vue-excel-column field="regId" label="등록자" readonly width="95px"/> -->
                        <!-- <vue-excel-column field="createdAt" label="등록일" readonly type="date" width="95px"/> -->
                        <!-- <vue-excel-column field="updId" label="수정자" readonly width="95px"/> -->
                        <!-- <vue-excel-column field="updatedAt" label="수정일" readonly type="date" width="95px"/> -->
                      </vue-excel-editor>
                    </BCard>
                  </BCol>

                </BRow>

              </BCard>
            </BCollapse>

          </BCard>

        </BCol>
      </BRow>

    </div>
  </div>
</template>

<script>
//-------------------------------------------------------------------------------------------------
// import moment from 'moment';
import '../../../common/HelperMixin';
import {
  PayTypeOpts, 
  TaxStatOpts, 
  PayStatOpts, 
  SendStatOpts, 
  PayMethods, 
  AcquCodes, 
  BankCodes,
} from '../../../common/salesType';

import {
  alertSuccess,
  apiCall,
  cloneVar,
  toastSync,
  alertWarn, alertConfirm, alertSync,
  commify, numberfy
} from '../../../common/utils';
import qs from 'querystring';
import moment from "moment";


const _member = {
  userId: null,
  userNo: null,
  pwdHash: null,
  name: null,
  email: null,
  role: null,
  level: null,
  lvEndDt: null,
  hpNo: null,
  telNo: null,
  nick: null,
  birth: null,
  zipNo: null,
  addr: null,
  addrDtl: null,
  compName: null,
  compNo: null,
  prName: null,
  position: null,
  payments: null,
  isMailing: null,
  isSms: null,
  agreeVer: null,
  isLogin: null,
  loginDt: null,
  loginIp: null,
};

const roles = {'ROLE_USER': '일반 관리자', 'ROLE_ADMIN': '수퍼 관리자'};
const _roleMap = {'U': '사용자', 'M': '담당자', 'A': '관리자'};
const _levelMap = {'00': '영구', '10': '임시', '20': '일반', 'P1': '프리미엄1', 'P2': '프리미엄2'};

//----------------------------------------------------------------------------------------------------
export default {
  name: 'XcostMember',
  data() {
    return {
      resCode: "3001",
      payMethods: PayMethods,
      acquCodes: AcquCodes,
      payTypeOpts: PayTypeOpts,
      payStatOpts: PayStatOpts,
      paramMap: {},
      rows: [],

      prdOpts: [],
      addLvDay: 0,
      addMaDay: 0,
      dt1: moment().format('YYYY-MM-DD'),
      dt2: moment().format('YYYY-MM-DD'),
      diffDt: 0,
      license: null,
      newUserId: '',
      newUserPwd: '',
      fromDts: moment().subtract(1,'month').format('YYYY-MM-DD'),
      userLogs: [],
      previewImage: null,
      imgFile: null,
      member: null,
      originPassword: '',
      newPassword: '',
      rePassword: '',
      isMemberSelected: false,
      isMemberFormShow: false,
      memberItems: [],
      perPage: 20,
      currPage: 1,
      roleMap: _roleMap,
      levelMap: _levelMap,
      userRoleOptions: [
        {value: 'ROLE_USER', text: '사용자'},
        {value: 'ROLE_ADMIN', text: '관리자'}
      ],
      isBusy: false,
      isRowSelect: false,

      searchOpts: [
        {value: 'userId', text: '회원ID'},
        {value: 'name', text: '이름/별명'},
        {value: 'compNo', text: '사업자번호'},
        {value: 'compName', text: '회사명'},
        {value: 'email', text: 'email'},
        {value: 'hpNo', text: '전화번호'},
      ],
      columnMap: {
        userId: '아이디',
        userNo: '번호',
        name: '이름',
        email: '이메일',
        role: '역할',
        level: '등급',
        lvEndDt: '등급만료일',
        hpNo: '휴대폰번호',
        telNo: '전화번호',
        nick: '별명',
        birth: '생일',
        zipNo: '우편번호',
        addr: '주소',
        addrDtl: '상세주소',
        compName: '회사명',
        taxNo: '사업자번호',
        prName: '대표자명',
        position: '직책',
        payments: '결제내역',
        isMailing: '메일수신',
        isSms: '메세지수신',
        agreeVer: '동의버전',
        isLogin: '로그인여부',
        loginDt: '로그일일시',
        logoutDt: '로그아웃일시',
        loginIp: '로그인 IP',
        status: '상태',
        regDts: '등록일',
      },

      statusMap: {
        '00': '정상',
        'ST': 'pwd 수정요청',
        'RP': 'pwd 수정중',
        'OK': 'pwd 수정완료'
      },
      searchWord: null,
      searchField: 'userId',
      actionMode: 'post',
      isAdmin: ($user.role === 'ROLE_ADMIN') ? true : false,
      progCount: 0,
      totalCount: 0,
      yearMonth: moment().format("YYYY"),
      yearMonthOpts: [],
      memberSchema: [
        {type: 'text', name: 'userId', label: '사용자 아이디', readonly: true,},
        {type: 'text', name: 'name', label: '사용자명', readonly: true,},
        {type: 'text', name: 'nick', label: '별명'},
        {type: 'date', name: 'lvEndDt', label: '사용자 등급 만료일', '@change': (e) => console.log(e.target.value)},
        {type: 'select', name: 'role', label: '사용자 역할', options: _roleMap},
        {type: 'select', name: 'level', label: '사용자 등급', options: _levelMap},
        {type: 'button', label: '저장', '@click': () => {this.clickSaveMember();}}
      ],
      licenses: [],
      licenseFields: [
        {key: 'level', label: '코드'},
        {key: 'prdName', label: '라이센스 명', tdClass: 'text-nowrap'},
        {key: 'lvEndDt', label: '기간', formatter: v=>{ return v? this.toYmd(v):''}},
        {key: 'maEndDt', label: 'MA기간', formatter: v=>{ return v? this.toYmd(v):''}},
        {key: 'createdAt', label: '등록일', formatter: v=>{ return v? this.toYmd(v):''}},
      ],
    }
  },
  watch: {
  },
  created() {
    console.log("-------created()--------");
    this.member = cloneVar(_member);
    this.getPrdCode();
    this.getMemberList();
  },
  computed: {
    userRows() {
      return this.memberItems.length;
    },

    flattenedData() {
      this.memberItems.forEach( row => {
        if (row.business) {
          row.compName = row.business.compName;
          row.prName   = row.business.prName;
          row.bizKind  = row.business.bizKind;
          row.compNo   = row.business.compNo;
          row.address  = row.business.address;
        }
      });
      return this.memberItems;
    }
  },

  methods: {
    toYmdHm(v){
      return v?moment(v).format('YY/MM/DD HH:mm'):'';
    },
    toYmd(v){
      return v?moment(v).format('YY/MM/DD'):'';
    },
    toComma(v){
      return v? commify(v):0;
    },
    toNumber(v){
      return v? numberfy(v):0
    },

    async getPrdCode() {
      console.log("getPrdCode ---- 1");
      this.prdOpts = [];
      this.prdOpts.push({value: null, text: '주문 상품 선택'});
      try {
        const r = await apiCall('get', `/admin/api/product/info`);
        r.result.map(i => {
          this.prdOpts.push({value: i.prd_code, text: i.prd_code + ' | ' + i.disp_name, disabled: (i.use_yn !== "Y")});
        });

      } catch (err) {
        console.log(err);
      }
    },

    toYmd(v){
      return v?moment(v).format('YY/MM/DD'):'';
    },
    diffDay(){
      let dt1 = moment(this.dt1);
      let dt2 = moment(this.dt2);
      this.diffDt = dt1.diff(dt2, 'days');
    },
    addDays(gbn) {

      if (gbn === 'lv') {
        this.license.lvEnd = moment(this.license.lvEndDt)
            .add(this.addLvDay, 'days')
            .format('YYYY-MM-DD');

      } else {
        this.license.maEnd = moment(this.license.maEndDt)
            .add(this.addMaDay, 'days')
            .format('YYYY-MM-DD');
      }
    },

    async setUserLicense(cmd){
      if(!this.license) {
        alert('선택된 라이센스 정보 없음');
        return;
      }

      let license = this.license;
      console.log( 'setUserLicense --->', this.license, cmd);

      try {
        if (!(await alertConfirm(this.$bvModal, `${license.level} 라이센스 ${cmd}, 진행 하시겠습니까?`, '사용자 라이센스 설정 확인'))) {
          return;
        }
        license.lvEndDt = moment(license.lvEnd).endOf('day').toISOString();
        license.maEndDt = moment(license.maEnd).endOf('day').toISOString();

        const r = await apiCall('post', `/admin/api/sales/set-license/${cmd}`, license );
        if( r.code===200 ){
          await toastSync(this.$bvToast, `${license.level} 라이센스 설정 완료`, 'primary');
          await this.getUserLicenses();
        }else{
          await toastSync(this.$bvToast, `${license.level} 라이센스 설정 실패`, 'danger');
        }
      } catch (err) {
        console.log(err);
      }
    },



    async createUser(){
      try{

        if(!this.newUserId) return alert('신규 사용자 아이디가 없습니다');
        if(!this.newUserPwd) return alert('신규 사용자 비밀번호가 없습니다.');

        if(this.newUserId.length < 5) return alert('신규 사용자 아이디는 5자 이상 입력하세요');

        const r = await apiCall('post',
            `/admin/api/member`,
            {
              userId: this.newUserId,
              userPwd: this.newUserPwd
            }
        );

        if(r.code===200) {
          await toastSync( this.$bvToast, `${this.newUserId}/${this.newUserPwd} 사용자 등록 성공`);
          await this.getMemberList();
          this.newUserId = '';
          this.newUserPwd = '';
        }else{
          await toastSync( this.$bvToast, `${this.newUserId} 사용자 등록 실패: ${r.message}`);
        }
      }catch(err){
        console.error(err);
        alert(err.message);
      }
    },
    async getUserLogs(){
      if(!this.member.userId) return;
      try{
        this.userLogs = [];
        let query = 'fromDts='+this.fromDts
        const r = await apiCall('get', `/admin/api/user/log/${this.member.userId}?${query}`);
        if(r.code===200) {
          this.userLogs = r.result;
          await toastSync( this.$bvToast, '로그인 이력 '+this.userLogs.length+'건 조회 완료');
          console.log('----------this.userLogs---###---->', r.result);
        }
      }catch(err){
        console.log(err);
      }
    },

    async clickSaveMember(evt) {
      evt.preventDefault();

      console.log("clickSave ----> member: ", this.member);
      // alert(this.member._id);
      try {
        const param = {
          role: this.member.role,
          level: this.member.level,
          lvEndDt: moment(this.member.lvEndDt, 'YYYY-MM-DD 23:59:59').toISOString(),
          nick: this.member.nick,
          name: this.member.name,
        };
        console.log("clickSaveMember param ---> ", param);
        const r = await apiCall('put', `/admin/api/member/xcost/${this.member._id}`, param);
        console.log(r);
        if (r.code === 200) {
          await this.getMemberList();
          await alertSuccess(this.$bvModal, {text: "저장 되었습니다."});
        } else {
          await alertWarn(this.$bvModal, {text: "수정 실패"});
        }

      } catch (err) {
        console.log(err);
      }
    },
    toLocalTime(val) {
      // console.log(val);
      return val?moment(val).format("YYYY-MM-DD HH:mm"):''
    },
    toIsoTime(val) {
      // console.log(val);
      return moment(val, 'YYYY-MM-DD HH:mm').toISOString()
    },
    async getMemberList() {
      console.log("---------getMemberList-------");
      this.isRowSelect = false;
      let r = null;

      const param = {};
      if (this.searchWord) {
        param[this.searchField] = this.searchWord;
      }

      try {
        this.isBusy = true;
        r = await apiCall('get', `/admin/api/member/xcost?${qs.stringify(param)}`);
        // console.log( r );
        this.memberItems = (r.result) ? r.result : [];

        await toastSync(this.$bvToast, `${this.memberItems.length} 개 레코드 조회`, 'info');



      } catch (err) {
        console.log(err);
      } finally {
        this.isBusy = false;
      }
    },
    exportExcel() {
      const format = 'xlsx';
      const filename = 'xcost-member-info-' + moment().format('YYYY-MM-DD');
      this.$refs.memberGrid.exportTable(format, true, filename)
    },
    exportUserLogs(){
      const format = 'xlsx';
      const filename = 'xcost-userlogs-' + moment().format('YYYY-MM-DD');
      this.$refs['userLogGrid'].exportTable(format, false, filename)
    },

    async rowSelected(idx, isSelected) {
      try {
        this.isRowSelect = isSelected;
        if (isSelected) {
          console.log(`------------ userRowSelected ------------idx: ${idx}, event: ${isSelected}`);

          let obj = this.$refs['memberGrid'].getSelectedRecords()[0];
          console.log("before: ", obj.lvEndDt);
          obj.lvEndDt = moment(obj.lvEndDt).format('YYYY-MM-DD');
          console.log("after:", obj.lvEndDt);
          this.member = obj;
          // this.$nextTick(() => { });
          console.log("selectMember -------->", this.member);

          await this.getLicenses();
          await this.getUserLogs();
          await this.getSalesOrderList();

        } else {

          console.log("clearMember");
          this.member = cloneVar(_member);
          this.licenses = [];
          this.userLogs = [];
        }
      }catch(err){
        console.log(err);
      }

    },
    async licenseSelected(item){
      console.log("------------ licenseSelected ------------");
      if( item.length === 0 ) {
        this.license = null;
      }else{
        let r = item[0]
        r['lvEnd'] = moment(r.lvEndDt).endOf('day').format('YYYY-MM-DD');
        r['maEnd'] = moment(r.maEndDt).endOf('day').format('YYYY-MM-DD');
        this.addLvDay = 0;
        this.addMaDay = 0;
        this.license = r;
      }
    },

    /**
     * 비밀번호 변경 메일 전송
     * @returns {Promise<void>}
     */
    async sendPwdResetMail() {
      let mb = this.member;
      try {
        if (!(await alertConfirm(this.$bvModal, `${mb.name}(${mb.userId})의 비밀번호 변경 요청 메일을 ${mb.email}로 발송합니다.`, '메일전송 확인'))) {
          return;
        }

        const r = await apiCall('POST', '/admin/api/member/pwd-request-mail-send', this.member);
        console.log(r);
        if (r.result) {
          await alertSuccess(this.$bvModal, '비밀번호 변경요청 메일을 전송 하였습니다');
        }
        /// admin/api/member/reset-pwd-mail-send

      } catch (err) {
        console.log(err);
      }
    },
    async resetPwdForce() {
      let mb = this.member;
      this.newPassword = mb.userId + '!@#';
      try {
        if (!(await alertConfirm(this.$bvModal, `${mb.name}(${mb.userId})의 비밀번호를 초기화 합니다.`, '비밀번호 초기화 확인'))) {
          return;
        }
        const param = {
          userId: mb.userId,
          userPwd: this.newPassword
        };
        const r = await apiCall('POST', '/admin/api/member/pwd-reset', param);
        console.log(r);
        if (r.result.message === 'success') {
          await alertSuccess(this.$bvModal, `${mb.userId}의 비밀번호 초기화 및 메일을 전송 하였습니다`);
        }

        /// admin/api/member/reset-pwd-mail-send

      } catch (err) {
        console.log(err);
      }
    },

    async getSalesOrderList() {
      this.paramMap = [];
      this.$refs['orderGrid'].clearFilter();
      let r = null;
      let qry = '';
      this.paramMap['userIdP'] = this.member.userId;
      this.paramMap['payStatP'] = '00';

      // if (this.searchWord) this.paramMap[this.searchField] = this.searchWord;
      qry = qs.stringify(this.paramMap);
      console.log("query-string ---------- ", qry);
      try {
        this.rows = [];
        r = await apiCall('get', `/admin/api/sales/order?${qry}`);
        // console.log( "getSalesOrderList ----------> ", r);
        if (r.result) {
          this.rows = r.result;
          await toastSync(this.$bvToast, `${this.rows.length}건 조회됨`, 'primary');
        } else {
          await toastSync(this.$bvToast, `에러: ${r.code} ${r.message}`, 'danger');
        }

      } catch (err) {
        console.log(err);
      }

    },

    async updateXcostUser(rec) {

      let rc = rec[0];
      if (!rc.keys[0]) {
        console.log("nokey");
        return;
      }
      const tIndex = this.$refs['memberGrid'].rowIndex[rc.$id];
      console.log("updateXcostUser--------- rec[0]|rc --->", rc);
      console.log("updateXcostUser--------- rec[0].$id--->", rc.$id);
      console.log("updateXcostUser--------- rec[0].$id--->", this.$refs['memberGrid']);
      console.log("updateXcostUser--------- rowIndex--->", tIndex);
      let row = this.$refs['memberGrid'].table[tIndex];

      console.log("row ---------> ", row);

      let r = null, msg = null, notice = null;

      let param = {};
      const objectId = rc.keys[0];
      const fieldName = rc.name;
      param[fieldName] = rc.newVal;
      try {
        // const row = this.$refs['memberGrid'].currentRecord;
        console.log('param --->', param);
        r = await apiCall("PUT", `/admin/api/member/column/${objectId}`, param);
        if (r.code === 200) await toastSync(this.$bvToast, `${this.columnMap[fieldName]} 수정됨`, 'info');
        console.log(r);

      } catch (err) {
        await toastSync(this.$bvToast, `${this.columnMap[fieldName]} 수정 에러: ${err.message}`);
        console.log(err);
      }

    },

    async deleteMember() {

      let mb = this.member;
      this.progCount = 0;
      this.totalCount = mb.length;
      if (!mb) {
        await alertWarn(this.$bvModal, {text: "선택한 레코드가 없습니다."});
        return;
      }

      if (!(await alertConfirm(this.$bvModal, `${mb.userId}의 회원정보(웹 회원정보, 라이센스 포함)삭제 되며 복구가 불가능 합니다. 진행 하시겠습니까?`, '회원정보 삭제경고'))) {
        return;
      }

      let failCnt = 0;
      console.log("delete member --------->", mb);
      const r = await apiCall("DELETE", `/admin/api/member/xcost/${mb._id}`);
      console.log("approvalOrder----result--->", r);
      if (r.result) {
        this.progCount++;
        await this.getMemberList();
        this.member = cloneVar(_member);
        this.isRowSelect = false;
      } else {
        failCnt++;
      }

      await alertSync(this.$bvModal, {text: `삭제: ${this.progCount}건, 실패: ${failCnt}`});

    },

    async getLicenses(){
      let r;
      try{
        console.log( 'getLicenses()---------> userId', this.member.userId);
        r = await apiCall('get', `/admin/api/member/license/${this.member.userId}`);
        console.log( 'getLicenses()---------> result ', r  );
        if( r.code===200) this.licenses = r.result;

      }catch(err){
        console.log( err );
      }
    },

    async getUserLicenses(){
      try {
        const {userId} = this.member;
        const r = await apiCall('get', `/admin/api/sales/license/${userId}`);
        if( r.code===200 ){
          this.licenses = r.result;
          await toastSync(this.$bvToast, `${userId} 라이센스 조회 완료`, 'primary');
        }

      } catch (err) {
        console.log(err);
      }
    },


    async reCreateLicense(){
      let r;
      try{
        if(!this.member) return;

        const {userId} = this.member;

        if (!(await alertConfirm(this.$bvModal, `${userId}의 회원정보로 라이센스를 갱신 합니다. 진행 하시겠습니까?`, '라이센스 재발급'))) {
          return;
        }
        console.log( 'reCreateLicense()---------> userId', userId);
        r = await apiCall('post', `/admin/api/member/re-license/${userId}`);
        console.log( 'reCreateLicense()---------> result ', r  );
        if( r.code===200) {
          await this.getLicenses();
          await toastSync(this.$bvToast, `${userId}의 라이센스 정보 갱신 완료`);
        }else{
          await toastSync(this.$bvToast, `${userId}의 라이센스 정보 갱신 실패`);
        }

      }catch(err){
        console.log( err );
      }

    },

    async createLicense(){
      let r;
      try{
        if(!this.member) return;
        const {userId} = this.member;

        if (!(await alertConfirm(this.$bvModal, `${userId}의 기본 라이센스를 추가 합니다. 진행 하시겠습니까?`, '라이센스 추가 발급'))) {
          return;
        }
        console.log( 'createLicense()---------> userId', userId);
        r = await apiCall('post', `/admin/api/member/add-license/${userId}`);
        console.log( 'createLicense()---------> result ', r  );
        if( r.code===200) {
          await this.getLicenses();
          await toastSync(this.$bvToast, `${userId}의 라이센스 추가 완료`);
        }else{
          await toastSync(this.$bvToast, `${userId}의 라이센스 추가 실패`);
        }

      }catch(err){
        console.log( err );
      }

    }





  }
}
</script>
